import { ClientError } from "@data-types/client-error-types";

/**
 * Determines if the provided error is of type `ClientError`.
 *
 * @param {unknown} error - The value to check.
 *
 * @returns {error is ClientError} `true` if the error is a `ClientError`, otherwise `false`.
 */
export function isClientError(error: unknown): error is ClientError {
  return (
    typeof error === "object" &&
    error !== null &&
    "message" in error &&
    "details" in error
  );
}
