import { useGetCurrentOrganizationId } from "@custom-hooks/organizations";
import { useGetUserSession } from "@custom-hooks/user";
import { ClientError } from "@data-types/client-error-types";
import { FetchApiOptions_v2, fetchApiRoute_v2, isClientError } from "@lib/client-side";
import { GenericDialogWithForm, GenericTextarea, MessageType } from "@tw-components/ui/iso";
import React from "react";
import * as Yup from "yup";

/**
 * Props for the `ContactDialog` component.
 *
 * @property {boolean} isOpen - Determines if the dialog is open or closed.
 * @property {() => void} onClose - Callback function to close the dialog.
 * @property {string} [title="Contact us"] - Title displayed at the top of the dialog.
 * @property {string} [description] - Description displayed below the title.
 * @property {React.ReactNode} [body] - Body content displayed above the textarea.
 * @property {string} [submitButtonText="Send"] - Text for the submit button.
 * @property {string} [textAreaLabel="Questions, concerns or feedback"] - Label for the textarea input.
 * @property {ClientError | string[] | null} [error] - Error information, if any, to include in the submission.
 * @property {boolean} [canSendEmptyMessage=false] - Allows submission with an empty message if set to true.
 */
export type ContactDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  description?: string;
  body?: React.ReactNode;
  submitButtonText?: string;
  textAreaLabel?: string;
  error?: ClientError | string[] | string | null;
  canSendEmptyMessage?: boolean;
  type: "contact_us" | "support" | "logs" | "billing";
  source?: string;
};

/**
 * A contact dialog component for users to send messages.
 *
 * @param {ContactDialogProps} props - The properties for the ContactDialog component.
 *
 * @returns {JSX.Element} The rendered contact dialog.
 */
export function ContactDialog({
  isOpen,
  onClose,
  error,
  title = "Contact us",
  description,
  submitButtonText = "Send",
  textAreaLabel = "Questions, concerns or feedback",
  body,
  canSendEmptyMessage = false,
  type,
  source
}: ContactDialogProps): JSX.Element {
  const {
    data: session
  } = useGetUserSession();
  const organizationId = useGetCurrentOrganizationId();
  const validationSchema = Yup.object().shape({
    message: canSendEmptyMessage ? Yup.string() : Yup.string().required("Message is required")
  });
  return <GenericDialogWithForm<{
    message: string;
  }> initialValues={{
    message: ""
  }} validationSchema={validationSchema} validateOnMount={true} onSubmit={async (values, {
    setStatus,
    resetForm
  }) => {
    try {
      if (session) {
        const name = session.user.name;
        const email = session.user.email;
        const subject = `Message from ${name} | SQLite Cloud Dashboard ${source ? "- " + source : ""}`;
        const html = `
                  <h2>Message received from: </h2>
                  <ul>
                    <li> Name: ${name}</li>
                    <li> Email: ${email}</li> 
                    <li> Organization ID: ${organizationId} </li>
                  </ul>
                  <h2>User Message</h2>
                  <div>${values.message}</div>
                  ${error ? `<h2>Error Message</h2>
                        <div>${isClientError(error) ? JSON.stringify(error.message) : JSON.stringify(error, null, 4)}</div>
                        <h2>Error Details</h2>
                        <div>${isClientError(error) ? JSON.stringify(error.details) : ""}</div>` : ""} 
                `;
        const opt: FetchApiOptions_v2 = {
          method: "POST",
          endpoint: "/api/sendMail",
          endpointCallLocation: "Sending mail",
          serverType: "dashboard",
          body: {
            subject,
            html,
            type: type
          }
        };
        await fetchApiRoute_v2(opt);
        resetForm();
        setStatus({
          type: MessageType.Success,
          title: "Message sent successfully!",
          description: "Thank you for reaching out. We'll get back to you as soon as possible."
        });
      }
    } catch (error) {
      setStatus({
        type: MessageType.Error,
        title: "Message sending failed",
        description: "An error occurred while sending your message. Please try again."
      });
    }
  }} dialogProps={({
    values,
    isSubmitting,
    status,
    handleChange,
    handleBlur,
    setStatus
  }) => {
    return {
      onClose: onClose,
      open: isOpen,
      description: description,
      title: title,
      size: "lg",
      submitButtonProps: status ? undefined : {
        size: "medium",
        variant: "primary",
        label: submitButtonText,
        className: "tw-w-24"
      },
      body: status === undefined && <div>
              <div className="tw-mb-4">{body}</div>

              <GenericTextarea label={textAreaLabel} resizable={false} value={values.message} rows={6} name="message" onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
          handleChange(e);
          setStatus(undefined);
        }} onBlur={handleBlur} disabled={isSubmitting} />
            </div>
    };
  }} data-sentry-element="GenericDialogWithForm" data-sentry-component="ContactDialog" data-sentry-source-file="ContactDialog.tsx" />;
}